import { createStore } from 'vuex';

export default createStore({
	state: {
		displayName: null,
		toastQueue: [],

	},

	mutations: {
		push_toast(state, toastMesage) {
			state.toastQueue.push(toastMesage);
		},

		pop_toast(state) {
			state.toastQueue.pop();
		},

	}
})
