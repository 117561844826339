import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import { decodeJwt } from 'jose';
import store from './store/store';

const isTokenValid = () => {
	let isValid = false;

	try
	{
		const jwt = localStorage.getItem('jwt');
		if (jwt) {
			const { exp } = decodeJwt(jwt);
			if (Math.floor(Date.now() / 1000) < exp)
			{
				isValid = true;
			}
		} else {
			isValid = false;
		}
	}
	catch (error)
	{
		console.log('EXCEPTION VALIDATING JWT');
		isValid = false;
	}

	return isValid;
}

const requireAuth = (to, from, next) => {
	if (isTokenValid()) {
		next();
	} else {
		store.commit('push_toast', { severity: 'warn', summary: 'Notice', detail: 'User not logged in.', life: 5000 });
		next({ name: 'login' });
	}
}

const alreadyLoggedIn = (to, from, next) => {
	if (isTokenValid()) {
		store.commit('push_toast', { severity: 'success', summary: 'Info', detail: 'Welcome back!', life: 5000 });
		next({ name: 'dashboard' });
	} else {
		next();
	}
}

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./pages/Dashboard.vue'),
				beforeEnter: requireAuth
            },
			{
				path: '/spaces',
				name: 'spaces',
				component: () => import('./pages/Spaces.vue'),
				beforeEnter: requireAuth
			}
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
		beforeEnter: alreadyLoggedIn
    },
	{
		path: "/:catchAll(.*)",
		name: "notfound",
		component: () => import('./pages/NotFound.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router;
